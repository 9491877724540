@mixin flavour (
  $body-bg,
  $body-colour,
  $button-bg,
  $button-colour,
  $button-secondary-bg,
  $button-secondary-colour,
  $link-colour,
  $mobile-nav-bg,
  $mobile-nav-item-colour,
  $footer-bg,
  $footer-colour,
  $card-grid-wave-version,
  $card-first-title-colour,
  $card-third-title-colour,
  $donation-widget-bg,
  $donation-widget-colour,
  $quote-bg,
  $quote-colour
) {
  // Body
  background-color: $body-bg;
  color: $body-colour;

  .stripe-payment-elements-submit-button,
  .button,
  .fui-btn {
    background-color: $button-bg;
    color: $button-colour;

    &:hover {
      background-color: darken($button-bg, 10%);
      color: $button-colour;
    }
  }

  .button--secondary,
  input::file-selector-button {
    background-color: $button-secondary-bg;
    color: $button-secondary-colour;

    &:hover {
      background-color: darken($button-secondary-bg, 10%);
      color: $button-secondary-colour;
    }
  }

  a {
    color: $link-colour;
    text-decoration-color: rgba($link-colour, .4);

    &:hover {
      color: lighten($link-colour, 5%);
      text-decoration-color: $link-colour;
    }
  }

  .primary-nav {
    background-color: $mobile-nav-bg;
    color: $mobile-nav-item-colour;

    @media (min-width: map-get($breakpoints, lg)) {
      a {
        color: $link-colour;
      }
    }
  }

  .footer__top-wave {
    color: $footer-bg;
  }

  .site-footer__contents {
    background-color: $footer-bg;
    color: $footer-colour;

    a {
      text-decoration-color: rgba($footer-colour, .4);

      &:hover {
        text-decoration-color: $footer-colour;
      }
    }
  }

  .site-footer__bottom {
    border-top: 1px solid rgba($footer-colour, .1);
  }

  .card-grid__wave {
    background-image: url("/dist/images/graphics/card-grid__wave--1--#{$card-grid-wave-version}.svg");
  }

  .card:nth-child(3n + 1) .card__title a {
    color: $card-first-title-colour;
  }

  .card:nth-child(3n + 3) .card__title a {
    color: $card-third-title-colour;
  }

  .donation-widget__main,
  .membership-pricing-table__main {
    background-color: $donation-widget-bg;
    color: $donation-widget-colour;
  }

  .donation-widget__wave,
  .membership-pricing-table__wave {
    color: $donation-widget-bg;
  }

  .donation-widget__amounts label {
    border: 2px solid $donation-widget-colour;
    color: $donation-widget-colour;

    &.active {
      background-color: $donation-widget-colour;
      color: $donation-widget-bg;
    }
  }

  .donation-widget__actions-label {
    color: $donation-widget-colour;
  }

  .membership-tier {
    border: 2px solid $donation-widget-colour;
  }

  .membership-tier__price {
    background-color: $button-secondary-bg;
  }

  .quote__main {
    background-color: $quote-bg;
    color: $quote-colour;
  }

  .quote__wave {
    color: $quote-bg;
  }

  .profile {
    border-color: rgba($body-colour, .2);
  }

  .tag {
    background-color: $body-colour;

    @if $body-colour == $white {
      color: $black;
    } @else {
      color: $white;
    }
  }

  @media (min-width: map-get($breakpoints, sm)) {
    .hero__slide[data-link]:hover .hero__slide__heading a {
      color: $link-colour;
    }
  }
}

.flavour--mustard {
  @include flavour (
    $body-bg: $mustard,
    $body-colour: $black,
    $button-bg: $orange,
    $button-colour: $white,
    $button-secondary-bg: $green,
    $button-secondary-colour: $white,
    $link-colour: $green,
    $mobile-nav-bg: $green,
    $mobile-nav-item-colour: $white,
    $footer-bg: $navy,
    $footer-colour: $white,
    $card-grid-wave-version: "dark",
    $card-first-title-colour: $plum,
    $card-third-title-colour: $teal--on-mustard,
    $donation-widget-bg: $green,
    $donation-widget-colour: $white,
    $quote-bg: $plum,
    $quote-colour: $white
  );
}

.flavour--dust {
  @include flavour (
    $body-bg: $dust,
    $body-colour: $black,
    $button-bg: $orange,
    $button-colour: $white,
    $button-secondary-bg: $teal,
    $button-secondary-colour: $white,
    $link-colour: $green,
    $mobile-nav-bg: $green,
    $mobile-nav-item-colour: $white,
    $footer-bg: $plum,
    $footer-colour: $white,
    $card-grid-wave-version: "dark",
    $card-first-title-colour: $plum,
    $card-third-title-colour: $teal--on-mustard,
    $donation-widget-bg: $plum,
    $donation-widget-colour: $white,
    $quote-bg: $orange,
    $quote-colour: $white
  );
}

.flavour--teal {
  @include flavour (
    $body-bg: $teal,
    $body-colour: $white,
    $button-bg: $orange,
    $button-colour: $white,
    $button-secondary-bg: $green,
    $button-secondary-colour: $white,
    $link-colour: $mustard,
    $mobile-nav-bg: $mustard,
    $mobile-nav-item-colour: $plum,
    $footer-bg: $green,
    $footer-colour: $white,
    $card-grid-wave-version: "light",
    $card-first-title-colour: $mustard,
    $card-third-title-colour: $mustard,
    $donation-widget-bg: $navy,
    $donation-widget-colour: $white,
    $quote-bg: $plum,
    $quote-colour: $white
  );
}

.flavour--orange {
  @include flavour (
    $body-bg: $orange,
    $body-colour: $black,
    $button-bg: $teal,
    $button-colour: $dust,
    $button-secondary-bg: $mustard,
    $button-secondary-colour: $black,
    $link-colour: $dust,
    $mobile-nav-bg: $teal,
    $mobile-nav-item-colour: $dust,
    $footer-bg: $plum,
    $footer-colour: $white,
    $card-grid-wave-version: "dark",
    $card-first-title-colour: $dust,
    $card-third-title-colour: $dust,
    $donation-widget-bg: $navy,
    $donation-widget-colour: $white,
    $quote-bg: $plum,
    $quote-colour: $white
  );
}

.flavour--green {
  @include flavour (
    $body-bg: $green,
    $body-colour: $white,
    $button-bg: $mustard,
    $button-colour: $black,
    $button-secondary-bg: $teal,
    $button-secondary-colour: $white,
    $link-colour: $mustard,
    $mobile-nav-bg: $mustard,
    $mobile-nav-item-colour: $black,
    $footer-bg: $teal,
    $footer-colour: $white,
    $card-grid-wave-version: "light",
    $card-first-title-colour: $mustard,
    $card-third-title-colour: $mustard,
    $donation-widget-bg: $teal,
    $donation-widget-colour: $white,
    $quote-bg: $navy,
    $quote-colour: $white
  );
}

.flavour--plum {
  @include flavour (
    $body-bg: $plum,
    $body-colour: $white,
    $button-bg: $mustard,
    $button-colour: $plum,
    $button-secondary-bg: $orange,
    $button-secondary-colour: $white,
    $link-colour: $mustard,
    $mobile-nav-bg: $mustard,
    $mobile-nav-item-colour: $plum,
    $footer-bg: $black,
    $footer-colour: $white,
    $card-grid-wave-version: "light",
    $card-first-title-colour: $mustard,
    $card-third-title-colour: $mustard,
    $donation-widget-bg: $green,
    $donation-widget-colour: $white,
    $quote-bg: $dust,
    $quote-colour: $black
  );
}