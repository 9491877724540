.donation-widget__main {
  h3 {
    margin-top: 0;
  }
}

.donation-widget__wave svg {
  width: 100%;
}

.donation-widget__amounts {
  display: flex;
  margin: -.8rem;
  max-width: 600px;
  margin-bottom: 1.6rem;

  input[type="radio"] {
    display: none;
  }

  label {
    margin: .8rem;
    display: inline-flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 1rem 2.4rem .8rem;
    background-color: transparent;
    border-radius: .6rem;
    font-size: 2.4rem;
    font-weight: bold;
    cursor: pointer;
  }
}

.donation-widget__actions-label {
  font-size: 1.8rem;
}

.donation-widget__actions {
  display: flex;
  max-width: 400px;
  position: relative;

  &:before {
    content: "£";
    display: block;
    opacity: .5;
    color: initial;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
  }

  button {
    margin-top: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    flex-grow: 0;
    flex-shrink: 0;
  }

  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 0;
    padding-left: 2.4rem;
  }
}