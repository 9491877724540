.site-footer {
  width: 100%;
  @include margin(8, top);

  svg {
    width: 100%;
  }
}

.site-footer__contents {
  width: 100%;
  @include padding(6, bottom);
  font-size: 1.6rem;

  a {
    color: inherit;
  }

  h2 {
    font-size: 2.4rem;
    margin-top: 0;
    margin-bottom: .8rem;
  }
}

.site-footer__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1090px;
  padding: 0 20px;
  margin: 0 auto;
}

.site-footer__newsletter {
  margin-bottom: 4.8rem;
  p {
    margin-bottom: 1.6rem;
  }
}

.site-footer__nav {
  text-align: center;
  margin-bottom: 4.8rem;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  li {
    margin-bottom: .4rem;
  }
}

.site-footer__logos {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 4.8rem;

  a {
    margin-bottom: 2.4rem;
    transition: opacity $transition;

    &:hover {
      opacity: .6;
    }
  }

  img {
    display: block;
    width: 100%;
    max-width: 180px;
    height: auto;
  }
}

.site-footer__bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1.6rem;
  font-size: 1.4rem;
}

.site-footer__social {
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  margin: 1.6rem -.8rem 0;

  li {
    margin: 0 .8rem;
  }
}

@media (min-width: map-get($breakpoints, md)) {
  .site-footer__container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .site-footer__newsletter {
    width: 50%;
  }

  .site-footer__nav,
  .site-footer__logos {
    text-align: initial;
    margin-bottom: 0;
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: 1.6rem;
  }

  .site-footer__nav ul {
    align-items: flex-start;
  }

  .site-footer__logos {
    align-items: flex-end;
  }

  .site-footer__bottom {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    text-align: initial;
  }

  .site-footer__social {
    margin-top: 0;
  }
}