.site-header {
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
}

@media (min-width: map-get($breakpoints, lg)) {
  .site-header {
    padding: 3rem;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    position: fixed;
    width: 26rem;
    z-index: 1;
  }
}