.site-logo img {
  width: auto;
  height: 80px;
  flex-shrink: 0;
}

@media (min-width: map-get($breakpoints, lg)) {
  .site-logo img {
    width: 200px;
    height: auto;
  }
}