.masonry {
  // outline: 1px solid red;
}

.masonry__row {
  display: grid;
  gap: 3rem;

  &:not(:last-child) {
    margin-bottom: 3rem;
  }
}

.masonry__cell {
  aspect-ratio: 16 / 9;
  display: flex;
  border-radius: .6rem;
  box-shadow: $box-shadow;
  overflow: hidden;

  a {
    display: block;
    width: 100%;
    transition: opacity $transition;
    &:hover {
      opacity: .6;
    }
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

@media (min-width: map-get($breakpoints, md)) {
  .masonry__row {
    &:nth-child(odd) {
      grid-template-columns: 57% 1fr;
      grid-template-rows: 320px;

      .masonry__cell {
        aspect-ratio: initial;
      }
    }

    &:nth-child(even) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}