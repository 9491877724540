.membership-pricing-table__wave svg {
  width: 100%;
}

.membership-pricing-table__main .container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.membership-tier {
  display: flex;
  flex-direction: column;
  border: 2px solid currentColor;
  border-radius: .6rem;
  padding: 2.4rem;
}

.membership-tier__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 0;
  margin-bottom: .8rem;

  h3 {
    margin: 0 .8rem 0 0;
  }
}

.membership-tier__price {
  font-size: 1.8rem;
  display: block;
  padding: .4rem .8rem;
  border-radius: .4rem;
}

.membership-tier__body {
  flex-grow: 1;

  p {
    margin-bottom: .5rem;
  }

  ul {
    margin-top: 0;
  }
}

.membership-tier__footer {
  display: flex;
  justify-content: flex-end;
}

@media screen and (min-width: map-get($breakpoints, md)) {
  .membership-pricing-table__main .container {
    flex-direction: row;
  }

  .membership-tier {
    flex: 1;
  }
}