.card {
  width: 100%;
  margin: 1.5rem;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.card__image-link {
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 16/9;
  margin-bottom: 1.6rem;
}

.card[class*="card--horizontal"] .card__image-link {
  align-items: flex-start;
}

.card--horizontalImageOnLeft .card__image-link {
  justify-content: flex-start;
}

.card--horizontalImageOnRight .card__image-link {
  justify-content: flex-end;
}

.card__image-link--image-cant-be-cropped {
  overflow: hidden;
  aspect-ratio: initial;
}

.card__title {
  font-size: 3.2rem;
  margin: 0;

  a:not(:hover) {
    text-decoration: none;
  }
}

.card__price {
  font-weight: 600;
}

.card__image {
  border-radius: .6rem;
  box-shadow: $box-shadow;
  display: block;
  object-fit: contain;
}

.card__summary {
  margin-top: 1.6rem;
}

@media (min-width: map-get($breakpoints, md)) {
  .card-grid--1-cols {
    .card[class*="card--horizontal"] {
      flex-direction: row;

      .card__image-link {
        width: 59.39%;
        flex-grow: 0;
        flex-shrink: 0;
        margin: 0;
      }

      .card__body {
        flex-grow: 1;
      }
    }

    .card--horizontalImageOnLeft .card__body {
      padding-left: 2.4rem;
    }

    .card--horizontalImageOnRight .card__body {
      padding-right: 2.4rem;
    }
  }
}

@media (min-width: map-get($breakpoints, lg)) {
  .card-grid--1-cols {
    .card[class*="card--horizontal"] .card__body {
      padding-top: 3.2rem;
    }

    .card--horizontalImageOnLeft .card__body {
      padding-left: 4rem;
    }

    .card--horizontalImageOnRight .card__body {
      padding-right: 4rem;
    }
  }
}