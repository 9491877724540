.matrix--profile {
  @include margin(5, top);
  @include margin(5, bottom);
}

.matrix--profile + .matrix--profile {
  margin-top: (map-get($spacing, 5) * .75) * -1;

  @media (min-width: map-get($breakpoints, md)) {
    margin-top: map-get($spacing, 5) * -1;
  }

  .profile {
    border-top: 0;
  }
}

.profile {
  width: 100%;
  display: flex;
  flex-direction: column;
  @include padding(4, top);
  @include padding(4, bottom);
  border-top: 1px solid;
  border-bottom: 1px solid;
}

.profile__image {
  border-radius: .6rem;
  margin-bottom: 1.6rem;
  display: block;
  box-shadow: $box-shadow;
  flex-grow: 0;
  flex-shrink: 0;
}

.profile__contents {
  h2 {
    margin-bottom: 0;
  }
}

.profile__title {
  display: inline-block;
  font-size: 2.4rem;
}

@media (min-width: map-get($breakpoints, md)) {
  .profile {
    flex-direction: row;
    align-items: flex-start;
  }

  .profile__image {
    width: 33%;
    margin-bottom: 0;
  }

  .profile__contents {
    flex-grow: 1;
    margin-left: 3.2rem;
  }
}