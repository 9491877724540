.image-gallery--carousel {
  margin-bottom: 2.4rem;
  padding-bottom: 3.6rem;

  .splide__list {
    align-items: center;
  }

  img {
    border-radius: .8rem;
    max-height: calc(100vh - 4.8rem);
    margin: 0 auto;
  }
}

.image-gallery--carousel-controls {
  .splide__list {
    align-items: center;
  }

  .splide__slide {
    opacity: .6;
    transition: opacity $transition;

    &.is-active,
    &:hover {
      opacity: 1;
    }
  }

  img {
    border-radius: .6rem;
  }
}