// Colours
$black: #090f0a;
$navy: #181e39;
$orange: #da7800;
$teal: #007579;
$teal--on-mustard: #004f52;
$green: #0f4c24;
$mustard: #bfb900;
$dust: #e7fbea;
$plum: #680036;
$white: #fff;
$red: #E61923;

// Breakpoints
$breakpoints: (
  sm: 640px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  2xl: 1536px
);

$spacing: (
  1: .8rem,
  2: 1.2rem,
  3: 1.6rem,
  4: 2.4rem,
  5: 3.2rem,
  6: 4.8rem,
  7: 6.4rem,
  8: 9.6rem
);

$transition: .1s ease-out;

$box-shadow: 0px 4px 4px rgba($black, 0.25);