.hero__slide {
  position: relative;

  > img {
    border-radius: .8rem;
    box-shadow: $box-shadow;
    margin: 0 auto;
    transition: $transition;
  }

  &[data-link] {
    cursor: pointer;
  }
}

.hero--carousel__splide .hero__slide > img {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.hero__slide__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.hero__slide__overlay--carousel {
  display: none;
}

.hero__slide__heading {
  margin: 0;
  padding: 2.4rem;
  background-color: $black;
  border-bottom-right-radius: .8rem;
  border-bottom-left-radius: .8rem;

  a {
    color: $white;

    &:not(:hover) {
      text-decoration: none;
    }
  }

  svg {
    width: 2.4rem;
    height: 2.4rem;
    margin-left: .8rem;
    display: inline-block;
    path {
      fill: currentColor;
    }
  }
}

@media (min-width: map-get($breakpoints, sm)) {
  .hero__slide[data-link] {
    background-color: $black;

    &:hover {
      img {
        opacity: .75;
      }

      .hero__slide__heading a {
        text-decoration: underline;
        text-underline-offset: .2em;
        text-decoration-thickness: 1px;
      }
    }
  }

  .hero--carousel__splide .hero__slide > img {
    border-bottom-right-radius: .8rem;
    border-bottom-left-radius: .8rem;
  }

  .hero__slide__overlay--carousel {
    display: block;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 22.45%, rgba(0, 0, 0, 0) 45.08%);
    border-radius: .8rem;
  }

  .hero__slide__heading {
    background-color: transparent;
    padding: 0;
    border-radius: 0;
    position: absolute;
    bottom: 8.4rem;
    left: 2.4rem;
    right: 2.4rem;
  }
}