.splide__track--nav>.splide__list>.splide__slide.is-active {
  border-color: transparent;
}

.splide__arrow {
  background-color: transparent;
  border-radius: 0;

  svg {
    display: block;
    width: 100%;
    height: 100%;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
}