.related-people {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 3.2rem;
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
  border-top: 1px solid $black;
  border-bottom: 1px solid $black;
}

.related-person {
  display: flex;
  align-items: center;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 2.4rem;
  }

  img {
    border-radius: 100%;
    margin-right: 1.6rem;
    flex-grow: 0;
    flex-shrink: 0;
  }
}

@media (min-width: 1380px) {
  .related-people {
    border: 0;
    padding: 0;
    margin: 0;
    position: absolute;
    width: 200px;
    top: 0;
    right: 0;
  }

  .related-person {
    flex-direction: column;
    width: auto;
    align-items: flex-start;
    font-size: 1.4rem;

    picture {
      width: 100%;
    }

    img {
      border-radius: .4rem;
      width: 100%;
      margin-right: 0;
      margin-bottom: .8rem;
    }
  }
}