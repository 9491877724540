html {
  min-height: 100vh;
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex-grow: 1;
}

.container {
  max-width: 1600px;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
}

.container--narrow {
  max-width: 740px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.page-intro {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

// Matrix block spacing

.matrix {
  > .container,
  > .container > .container--narrow {
    > .lc--nbm {
      width: 100%;
    }
  }
}

.matrix--button {
  @include margin(7, bottom);
}

.matrix--cards,
.matrix--quote,
.matrix--video,
.matrix--image,
.matrix--imageGallery,
.matrix--donationWidget {
  @include margin(6, top);
  @include margin(7, bottom);
}

.image__image--medium {
  max-width: 740px;
}

.image__image--narrow {
  max-width: 540px;
}

@media (min-width: map-get($breakpoints, lg)) {
  .container {
    padding: 0 9rem 0 35rem;
  }
}

@media (min-width: 1380px) {
  .page-intro {
    flex-direction: row;
    justify-content: space-between;
    position: relative;

    > .container--narrow {
      flex-grow: 1;
    }
  }
}