.site-sub-header {
  display: none;
}

@media (min-width: map-get($breakpoints, lg)) {
  .site-sub-header {
    display: flex;
    justify-content: flex-end;
    padding: 3rem 3rem 3rem 26rem;
  }
}