.quote__quote {
  max-width: 720px;
  font-weight: 500;
  position: relative;
  font-size: 1.8rem;
}

.quote__quote--handwritten {
  font-size: 2.4rem;
  @include ssa-hand;
}

.quote__marks {
  margin-bottom: .8rem;
}

.quote__reference {
  font-size: 1.6rem;
  display: inline-block;
  margin-top: 1.6rem;
}

.quote__wave svg {
  width: 100%;
}

@media (min-width: map-get($breakpoints, lg)) {
  .quote__quote {
    font-size: 2.8rem;
  }

  .quote__quote--handwritten {
    font-size: 3.2rem;
  }

  .quote__marks {
    position: absolute;
    top: 0;
    left: -52px;
  }
}