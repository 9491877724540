.breadcrumb {
  ol {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: flex-start;
    font-size: 1.6rem;
  }

  li {
    display: inline-flex;
    align-items: center;
    &:after {
      content: "";
      display: block;
      width: .6rem;
      height: 1.05rem;
      margin-left: .8rem;
      margin-top: .2rem;
      background-image: url("/dist/images/icons/breadcrumb__chevron.svg");
      background-size: contain;
    }
  }

  li:not(:last-child) {
    margin-right: .8rem;
  }

  a:not(:hover) {
    text-decoration: none;
  }

  &--back li {
    &:after {
      display: none;
    }

    &:before {
      content: "";
      display: block;
      width: .6rem;
      height: 1.05rem;
      margin-right: .8rem;
      margin-top: .2rem;
      background-image: url("/dist/images/icons/breadcrumb__chevron.svg");
      background-size: contain;
      transform: rotate(180deg);
    }
  }
}