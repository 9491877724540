form,
.fui-i {
  width: 100%;
}

.matrix--form {
  @include margin(5, top);
  @include margin(5, bottom);
}

input[type="text"],
input[type="email"],
input[type="number"],
input[type="tel"],
textarea,
select,
#card-element,
.StripeElement {
  width: 100%;
  padding: .8rem;
  background-color: $white;
  border: 1px solid rgba($black, .5);
  border-radius: .4rem;
}

button,
.button,
input[type="submit"] {
  @include ssa-hand;
  line-height: 1.6;
  margin-top: 1.6rem;
  cursor: pointer;
}

label,
.fui-legend {
  font-size: 1.4rem;
  display: inline-block;
  margin-bottom: .4rem;
}

.fui-instructions {
  font-size: 1.4rem;
  margin-bottom: .8rem;
  opacity: .8;

  > *:last-child {
    margin-bottom: 0;
  }
}

.fui-limit-text {
  text-align: right;
  font-size: 1.4rem;
  opacity: .8;
}

fieldset {
  margin: 0 0 1.6rem;
  padding: 0;
  border: 0;
}

.fui-fieldset {
  margin-bottom: 0;
}

.fieldset--hidden {
  display: none;
}

.choice-row {
  display: flex;
  align-items: flex-start;

  input {
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: .8rem;
    margin-top: .5rem;
  }

  label {
    flex-grow: 1;
  }
}

.fui-field {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  margin-bottom: .6rem;

  &.fui-type-file-upload,
  &.fui-type-agree {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.fui-input-container {
  width: 100%;
}

.fui-row {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
  margin-bottom: .6rem;
}

.field-row,
.mc-field-group {
  margin-bottom: 1.2rem;
}

.checkout-form {
  form,
  .container--narrow {
    width: 100%;
  }

  .cart-summary {
    margin-bottom: 3.2rem;
  }
}

.field-row--gift-aid {
  img {
    display: block;
    margin-bottom: 1.6rem;
  }

  label {
    font-size: 1.6rem;
  }
}

.stripe-payment-intents-form {
  width: 100% !important;
  margin-bottom: 1.2rem;
}

.card-holder {
  legend {
    @extend label;
  }

  > div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: -.8rem;
    margin-bottom: 1.2rem;

    > div {
      flex: 1;
      margin: .8rem;
    }
  }
}

.fui-alert {
  padding:2.4rem;
  background-color: rgba($black, .1);
  border-radius: .8rem;
  margin-bottom: 2.4rem;

  > *:last-child {
    margin-bottom: 0;
  }
}

// Radio and checkboxes
.fui-radio,
.fui-checkbox {
  display: flex;

  &:not(:last-child) {
    margin-bottom: .4rem;
  }

  input {
    flex-grow: 0;
    margin-right: .8rem;
    transform: scale(1.2);
  }

  label {
    margin-bottom: 0;
  }
}

@media (min-width: map-get($breakpoints, sm)) {
  .field-row--2-col {
    display: flex;
    margin-left: -.8rem;
    margin-right: -.8rem;

    > * {
      margin: 0 .8rem;
      flex: 1;
    }
  }
}

@media (min-width: map-get($breakpoints, xl)) {
  .checkout-form {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: flex-start;

    form {
      flex-shrink: 0;
    }

    .cart-summary {
      margin: 0 0 0 4.8rem;
      flex-grow: 1;
      flex-shrink: 0;
    }
  }
}