#menuButton {
  margin: 0;
  position: fixed;
  top: 6rem;
  transform: translateY(-50%);
  right: 2rem;
  z-index: 2;
  height: 44px;
}

.primary-nav {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  a {
    color: inherit;
  }
}

@media (min-width: map-get($breakpoints, lg)) {
  #menuButton {
    display: none;
  }

  .primary-nav {
    background-color: transparent !important;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    text-align: right;
    position: initial;
  }
}