.matrix--textTiles {
  margin-top: 3.2rem;
  margin-bottom: 4.8rem;
}

.text-tiles {
  display: flex;
  flex-wrap: wrap;
  margin: -.8rem;
  list-style: none;
  padding: 0;
}

.text-tile {
  width: 100%;
  margin: .8rem;
  display: flex;
  padding: 1.6rem;
  border-radius: .6rem;
  background-color: rgba($black, .1);
  font-size: 2rem;
}

@media (min-width: map-get($breakpoints, sm)) {
  .text-tile {
    width: calc((100% - 3.2rem) / 2);
    padding: 2.4rem;
  }
}