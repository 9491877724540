.variant-form,
.cart-form {
  width: 100%;
}

.variant-rows {
  border-bottom: 2px solid rgba($black, .2);
  margin-bottom: 2.4rem;
}

.variant-row {
  display: flex;
  width: 100%;
  align-items: center;
  border-top: 1px solid rgba($black, .2);
  padding: .8rem 0;
}

.variant-row__title {
  flex-grow: 1;
  font-weight: 600;
  margin-right: 1.6rem;
}

.variant-row__price {
  flex-grow: 0;
  margin-right: 1.6rem;
  text-align: right;
}

.variant-row__input {
  flex-grow: 0;
  max-width: 140px;
}

.product-actions {
  display: flex;
  justify-content: flex-end;

  > * {
    margin-left: .8rem;
  }
}

.cart {
  width: 100%;

  th {
    text-align: left;
    padding: 0;
  }

  tr {
    padding: 1.2rem 0;
    border-top: 1px solid rgba($black, .2);
  }

  th, td {
    padding: .8rem 0;

    &:not(:last-child) {
      padding-right: 1.6rem;
    }

    &:nth-child(2) input {
      width: 140px;
    }

    &:nth-child(3) {
      text-align: right;
    }
  }

  .cart-total-row td {
    border-top: 2px solid rgba($black, .2);

    &:first-child {
      text-align: right;
      font-weight: bold;
    }
  }
}

.cart-item-row__donation-amount {
  position: relative;

  &:before {
    content: "£";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%) translateX(calc(-100% - .8rem));
  }
}

.remove-cart-item {
  display: none;

  + label {
    cursor: pointer;
    color: $red;
    font-weight: 600;
  }
}

.cart-nav-item--mobile {
  position: fixed;
  width: 44px;
  height: 44px;
  right: 13rem;
  z-index: 2;
  padding: 0;
}

.cart-nav-item.button {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    display: block;
    width: 2.4rem
  }
}

.cart-nav-item--desktop {
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  margin-right: 1.2rem;
  position: relative;
}

.cart-nav-item__indicator {
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 100%;
  background-color: red;
  display: block;
  position: absolute;
  top: -.8rem;
  right: -.8rem;
}

.cart-nav-item__price {
  display: none;
}

@media (min-width: map-get($breakpoints, lg)) {
  .cart-nav-item--mobile.button {
    display: none;
  }

  .cart-nav-item__indicator {
    display: none;
  }

  .cart-nav-item__price {
    display: block;
    margin-left: .8rem;
  }
}