.partner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2.4rem 0;
  border-top: 1px solid rgba($black, .2);
  border-bottom: 1px solid rgba($black, .2);
}

.matrix--partner + .matrix--partner .partner {
  border-top: none;
}

.partner__logo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: $white;
  border-radius: .8rem;
  box-shadow: $box-shadow;
  margin-bottom: 1.6rem;

  img {
    display: block;
    width: 100%;
    height: auto;
    max-width: 400px;
    max-height: 200px;
  }
}