.video {
  width: 100%;
  position: relative;
  border-radius: .8rem;
  overflow: hidden;
  box-shadow: $box-shadow;
}

.video__frame {
  position: relative;
  padding-top: 56.25%;
  background-color: $black;
  overflow: hidden;

  &.video__playing .video__button {
    display: none;
  }
}

.video__asset,
.video__button {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.video__button {
  padding: 0;
  margin: 0;
  border: 0;
  cursor: pointer;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }

  &:hover {
    .video__shade {
      background-color: rgba($black, .4);
    }

    .video__play-icon {
      transform: translateY(-50%) scale(1.05);
    }
  }
}

.video__play-icon {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  transform: translateY(-50%);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  z-index: 1;
  transition: all $transition;
}

.video__shade {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($black, .2);
  transition: all $transition;
}