.tags {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin: -.2rem;
}

.card__body .tags {
  margin-bottom: 1.4rem;
}

.page-intro .tags {
  margin-top: 3rem;
  margin-bottom: .6rem;
}