.image-grid-with-labels {
  display: grid;
  gap: 3rem;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.image-grid-with-labels__label {
  margin-top: .8rem;
}

@media (min-width: map-get($breakpoints, sm)) {
  .image-grid-with-labels {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: map-get($breakpoints, xl)) {
  .image-grid-with-labels {
    grid-template-columns: 1fr 1fr 1fr;
  }
}