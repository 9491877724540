@font-face {
  font-family: "SSA-Hand";
  src: url("/static/fonts/SSA-Hand.woff2") format("woff2"), url("/static/fonts/SSA-Hand.woff") format("woff");
}

html {
  -webkit-text-size-adjust: 100%;
  font-feature-settings: "pnum" on, "lnum" on, "ss04" on;
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  line-height: 1.6;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

@mixin ssa-hand {
  font-family: "SSA-Hand", serif;
  text-transform: uppercase;
  line-height: 1.2;
  font-weight: 400;
}

h1, h2, h3 {
  @include ssa-hand;
  margin-top: .33em;
  margin-bottom: .66em;
}

a {
  text-underline-offset: .2em;
  text-decoration-thickness: 1px;
}

p {
  margin-bottom: 1.6em;
}

.p-large {
  font-size: 1.8rem;
}

.small {
  font-size: 1.4rem;
}

.media-caption {
  display: inline-flex;
  margin-top: 1.6rem;
}

@media (min-width: map-get($breakpoints, md)) {
  body {
    font-size: 1.8rem;
  }

  h1 {
    font-size: 4.8rem;
    margin-bottom: 3.2rem;
  }

  h2 {
    font-size: 3.5rem;
    margin-bottom: 2.4rem;
  }

  .p-large {
    font-size: 2.4rem;
  }
}