.matrix--cards {
  position: relative;
}

.card-grid__wave {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: 100% 90%;
  background-position: center;
  pointer-events: none;
}

.card-grid {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin: -1.5rem;
}

@media (min-width: map-get($breakpoints, sm)) {
  .card-grid--2-cols,
  .card-grid--3-cols {
    margin-top: -3rem;
    margin-bottom: -3rem;

    .card {
      margin-top: 3rem;
      margin-bottom: 3rem;
      width: calc((100% - 6rem) / 2);
    }
  }
}

@media (min-width: map-get($breakpoints, lg)) {
  .card-grid--1-cols[class*="card-type-horizontal"] {
    margin-top: -3rem;
    margin-bottom: -3rem;

    .card {
      margin-top: 3rem;
      margin-bottom: 3rem;
    }
  }
}

@media (min-width: map-get($breakpoints, xl)) {
  .card-grid--3-cols .card {
    width: calc((100% - 9rem) / 3);
  }
}