.fc--ntm > *:first-child {
  margin-top: 0;
}

.lc--nbm > *:last-child {
  margin-bottom: 0;
}

@mixin margin($size, $direction) {
  margin-#{$direction}: (map-get($spacing, $size)) * .75;

  @media (min-width: map-get($breakpoints, md)) {
    margin-#{$direction}: map-get($spacing, $size);
  }
}

@mixin padding($size, $direction) {
  padding-#{$direction}: (map-get($spacing, $size)) * .75;

  @media (min-width: map-get($breakpoints, md)) {
    padding-#{$direction}: map-get($spacing, $size);
  }
}

.box-shadow {
  box-shadow: $box-shadow;
}

.br--8 {
  border-radius: .8rem;
}