.caption-overlay {
  position: absolute;
  top: 1.4rem;
  right: 1.4rem;
  background-color: rgba($black, .8);
  color: $white;
  padding: .4rem 1rem;
  border-radius: .4rem;
  font-size: 1.4rem;
  line-height: 1.4;
}