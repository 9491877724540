.cart-summary {
  padding: 2.4rem;
  background-color: rgba($black, .1);
  border-radius: .8rem;
  max-width: 740px;

  h2 {
    font-size: 1.8rem;
  }

  h3 {
    font-size: 1.2rem;
  }
}

.cart-summary__item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}