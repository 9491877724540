.person-links {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  li {
    a {
      display: inline-flex;
      align-items: flex-start;

      svg {
        color: $black;
        display: block;
        height: auto;
        width: 1.4rem;
        flex-grow: 0;
        flex-shrink: 0;
        margin: .6rem .4rem 0 0;
      }

      span {
        word-break: break-all;
      }
    }
  }
}

@media (min-width: map-get($breakpoints, xl)) {
  .person-links li a svg {
    margin: .4rem .4rem 0 0;
  }
}