.date {
  display: flex;
  align-items: center;

  svg {
    display: block;
    height: auto;
    width: 1.6rem;
    margin-right: .8rem;
  }

  span {
    display: inline-block;
    margin-top: .2rem;
  }
}

.page-intro .date {
  margin-top: 3.2rem;
}

.page-intro .tags + .date {
  margin-top: 0;
}