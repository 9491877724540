a.button {
  text-decoration: none;
}

.button,
.fui-btn,
input::file-selector-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1rem 2.4rem .8rem;
  box-shadow: 0px 4px 1px rgba($black, 0.06);
  border-radius: .6rem;
  border: 0;

  span {
    @include ssa-hand;
    line-height: 1.6;
    text-shadow: 0px 1px 0px rgba($black, 0.1);
  }
}

input::file-selector-button,
.fui-btn {
  @include ssa-hand;
  line-height: 1.6;
  text-shadow: 0px 1px 0px rgba($black, 0.1);
}

input::file-selector-button {
  cursor: pointer;
}

.stripe-payment-elements-submit-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1rem 2.4rem .8rem;
  box-shadow: 0px 4px 1px rgba($black, 0.06);
  border-radius: .6rem;
  border: 0;
  line-height: 1.6;
  text-shadow: 0px 1px 0px rgba($black, 0.1);
}

.button__icon {
  width: 1.6rem;
  margin-top: -.2rem;
}

span + .button__icon {
  margin-left: .8rem;
}