.hero {
  @include margin(5, bottom);
}

.hero__wrapper {
  position: relative;

  .splide__track {
    overflow: initial;
  }

  .splide__list {
    align-items: center;
  }
}

.hero--carousel-controls__splide {
  display: none; // TODO ?
}

.hero--carousel__control {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: .8rem 2.4rem;
  @include ssa-hand;
  font-size: 1.6rem;
  background-color: transparent;
  border: 1px solid $white;
  color: $white;
  border-radius: .6rem;
}

.splide__slide.is-active .hero--carousel__control {
  background-color: $white;
  color: $black;
  position: relative;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: -8px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent $white transparent;
  }
}

@media (min-width: map-get($breakpoints, sm)) {
  .hero--carousel-controls__splide {
    display: block;
    position: absolute;
    bottom: 2.4rem;
    left: 2.4rem;
  }
}
